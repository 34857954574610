<template>
  <div class="columns pt-5 px-5">
    <b-skeleton height="10rem" size="is-large" :active="skeletonLoading">
    </b-skeleton>
    <div class="column" v-if="!skeletonLoading">
      <div class="block title question">
        <div class="survey-title">
          {{ tableTitle }}
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column is-full refresh-container">
          <b-button
            type="is-danger"
            class="refresh-button"
            icon-right="calendar-search"
            @click="reloadPatients"
          />
        </div>
      </div> -->

      <b-table
        :data="history"
        :bordered="false"
        :striped="false"
        :hoverable="true"
        :mobile-cards="true"
        :paginated="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :backend-pagination="true"
        :total="total"
        :sticky-header="true"
        :pagination-rounded="true"
        @page-change="makeSearch"
        type="is-info"
        class="mb-5"
        ref="tableHistory"
      >
        <b-table-column field="patientId" label="Paciente" v-slot="props">
          {{ props.row.patientId }}
        </b-table-column>

        <b-table-column
          field="created_at"
          label="Fecha de creación"
          v-slot="props"
        >
          {{ props.row.date | myDate }}
        </b-table-column>

        <b-table-column field="surveyCode" label="Encuesta" v-slot="props">
          {{ props.row.surveyCode }}
        </b-table-column>

        <b-table-column
          field="result"
          label="Resultado"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.result ? props.row.result : "No disponible" }}
        </b-table-column>

        <b-table-column
          label="Acciones"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          <b-button
            class="new-survey-btn"
            rounded
            @click="goToDetail(props.row)"
            >Ir a encuesta</b-button
          >
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Sin resultados</div>
        </template>
        <template #bottom-left>
          <b-select v-model="perPage">
            <option
              v-for="(item, index) in itemsPerPage"
              :key="index"
              :value="item"
              >{{ `${item} por página` }}</option
            >
          </b-select>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script scoped>
import DETAIL_PATHS from "../../enum/detailPaths";

export default {
  props: {
    tableTitle: {
      type: String,
      default: "",
    },
    patientId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      itemsPerPage: [10, 20, 30, 40, 50],
      perPage: 10,
      currentPage: 1,
      total: 0,
      skeletonLoading: true,
    };
  },
  mounted() {
    this.skeletonLoading = true;
    //this.seeHistory();
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  async created() {
    this.$global.$on("patientDataDeleted", () => {
      this.skeletonLoading = false;
    });

    this.$global.$on("patientLoaded", () => {
      this.seeHistory();
    });

    this.seeHistory();
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.seeHistory();
    },
    patientId: function() {
      this.currentPage = 1;
      this.seeHistory();
    },
  },
  methods: {
    scrolltoElement() {
      this.$nextTick().then(() => {
        const element = this.$refs.tableHistory?.$el;
        if (element) {
          // Use el.scrollIntoView() to instantly scroll to the element
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      });
    },
    async seeHistory() {
      this.changeLoaderStatus(true);
      try {
        const response = await this.$surveyService.getByPatientId(
          this.currentPage,
          this.perPage,
          this.patientId
        );

        this.history = response.data[0];
        this.total = response.data[1];
        this.skeletonLoading = false;
        this.scrolltoElement();
      } catch (error) {
        console.log(error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    goToDetail(router = {}) {
      this.$router
        .push({
          name: `detail-${DETAIL_PATHS[router.surveyCode]}`,
          params: { surveyId: router.id },
        })
        .catch(() => {});

      this.$global.$emit("setPatientInput", router.patientId);
    },
    async reloadPatients() {
      await this.seeHistory();
    },
    makeSearch(page) {
      this.currentPage = page;
      this.seeHistory();
    },
    newPatient() {
      this.$router.push({ name: "patients-create-edit" });
    },
    columnTdAttrs(row, col) {
      if (col.field == "appreciation") {
        if (row.appreciation === null || row.appreciation === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      } else if (col.field == "result") {
        if (row.result === null || row.result === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      }
    },
  },
};
</script>

<style>
.question {
  display: flex;
}

.survey-title {
  width: 50%;
  text-align: start;
}
.new-survey-container {
  width: 50%;

  text-align: end;
}

.refresh-container {
  text-align: right;
}

.active-status {
  color: var(--medium-blue-color);
  font-weight: bold;
}

.no-active-status {
  color: var(--dark-red-color);
  font-weight: bold;
}
</style>
