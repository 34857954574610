<template>
  <div class="container">
    <div class="columns pt-5 px-5">
      <div class="column">
        <div class="block title question">
          <div class="survey-title">
            Pacientes
          </div>

          <div class="new-survey-container">
            <b-button
              class="new-survey-btn"
              icon-right="plus"
              rounded
              @click="newPatient()"
              v-if="permissionValidation(permissions.CREATE_PATIENT)"
              >Nuevo paciente</b-button
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-full refresh-container">
            <b-button
              type="is-danger"
              class="refresh-button"
              icon-right="calendar-search"
              @click="reloadPatients"
            />
          </div>
        </div>
        <b-table
          :data="patients"
          :bordered="false"
          :striped="false"
          :hoverable="true"
          :mobile-cards="true"
          :paginated="true"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :backend-pagination="true"
          :total="total"
          :sticky-header="true"
          :pagination-rounded="true"
          @page-change="makeSearch"
          type="is-info"
          class="mb-5"
        >
          <!-- <b-table-column field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column> -->

          <b-table-column field="name" label="Nombre" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="document" label="Documento" v-slot="props">
            {{ props.row.document }}
          </b-table-column>

          <b-table-column
            field="created_at"
            label="Fecha de creación"
            v-slot="props"
          >
            {{ props.row.created_at | myDate }}
          </b-table-column>

          <b-table-column field="active" label="Estado" v-slot="props">
            <p
              :class="
                props.row.active == 1 ? 'active-status' : 'no-active-status'
              "
            >
              {{ props.row.active == 1 ? "ACTIVO" : "INACTIVO" }}
            </p>
          </b-table-column>

          <b-table-column
            label="Acciones"
            v-slot="props"
            v-if="
              permissionValidation(permissions.VIEW_PATIENT_HISTORY) ||
                permissionValidation(permissions.EDIT_PATIENT) ||
                permissionValidation(permissions.CHANGE_STATUS_OF_PATIENT)
            "
          >
            <b-dropdown
              aria-role="list"
              class="is-pulled-left ml-5"
              position="is-bottom-left"
            >
              <template #trigger>
                <b-icon icon="dots-vertical"></b-icon>
              </template>
              <b-dropdown-item
                aria-role="listitem"
                @click="seeHistory(props.row.document)"
                v-if="permissionValidation(permissions.VIEW_PATIENT_HISTORY)"
                >Histórico</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                @click="goToEdit(props.row)"
                v-if="permissionValidation(permissions.EDIT_PATIENT)"
                >Editar</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                @click="deletePatient(props.row.id, props.row.active)"
                v-if="
                  permissionValidation(permissions.CHANGE_STATUS_OF_PATIENT)
                "
                >Cambiar estado</b-dropdown-item
              >
            </b-dropdown>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">Sin resultados</div>
          </template>
          <template #bottom-left>
            <b-select v-model="perPage">
              <option
                v-for="(item, index) in itemsPerPage"
                :key="index"
                :value="item"
                >{{ `${item} por página` }}</option
              >
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
    <history-table
      v-if="historyLoading"
      tableTitle="Historial del paciente"
      :patientId="patientId"
      ref="tableH"
    />
  </div>
</template>

<script>
import HistoryTable from "../../../components/Administration/HistoryTable.vue";
import { permissions } from "../../../enum/applicationPermissions";

export default {
  components: {
    HistoryTable,
  },
  data() {
    return {
      permissions: permissions,
      patients: [],
      history: [],
      itemsPerPage: [10, 20, 30, 40, 50],
      perPage: 10,
      currentPage: 1,
      total: 0,
      historyLoading: false,
      patientId: null,
    };
  },
  async created() {
    this.$global.$on("patientDataDeleted", () => {
      this.getPatients();
      this.historyLoading = false;
    });

    this.$global.$on("patientLoaded", () => {
      this.patientSearch();
    });

    this.getPatients();
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getPatients();
    },
  },
  methods: {
    seeHistory(patientId) {
      this.historyLoading = false;
      this.changeLoaderStatus(true);
      this.patientId = patientId;
      this.historyLoading = true;
      this.changeLoaderStatus(false);
    },
    async patientSearch() {
      this.changeLoaderStatus(true);
      try {
        const response = await this.$patientService.findPatientByDocument(
          this.patient ? this.patient.document : null
        );
        this.patients = [response.data];
        this.total = 1;
        if (this.patient) this.seeHistory(this.patient.document);
      } catch (error) {
        console.log(error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    goToEdit(data) {
      this.$router.push({
        name: "patients-create-edit",
        params: { patient: data },
      });
    },
    async deletePatient(patientId, status) {
      try {
        this.changeLoaderStatus(true);

        let data = {
          status: status == 1 ? 0 : 1,
        };

        await this.$patientService.changePatientStatus(patientId, data);

        await this.getPatients();
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async getPatients() {
      this.changeLoaderStatus(true);
      try {
        if (this.patient) {
          this.patientSearch();

          return;
        }
        this.$global.$emit(
          "setPatientInput",
          this.patient ? this.patient?.document : null
        );
        const response = await this.$patientService.getPatients(
          this.currentPage,
          this.perPage
        );

        this.patients = response.data.items;
        this.total = response.data.total;
      } catch (error) {
        console.log(error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async reloadPatients() {
      await this.getPatients();
    },
    makeSearch(page) {
      this.currentPage = page;
      this.getPatients();
    },
    newPatient() {
      this.$router.push({ name: "patients-create-edit" });
    },
    permissionValidation(permission) {
      return this.$ability.can(permission);
    },
  },
};
</script>

<style scoped>
.question {
  display: flex;
}

.survey-title {
  width: 50%;
  text-align: start;
}
.new-survey-container {
  width: 50%;

  text-align: end;
}

.refresh-container {
  text-align: right;
}

.active-status {
  color: var(--medium-blue-color);
  font-weight: bold;
}

.no-active-status {
  color: var(--dark-red-color);
  font-weight: bold;
}
</style>
